<template>
    <li :class="classes">
        <router-link
        v-if="to"
        :to="to"
        :exact="exact"
        :disabled="disabled"
        class="bxs-list-item--content">
            <bxs-icon
            v-if="prependIcon || !!$slots.prependIcon"
            :name="prependIcon"
            :disabled="disabled"
            class="bxs-list-item-icon-prepend" />

            <bxs-spacer v-if="spacer && prependIcon"></bxs-spacer>

            <span>
                <slot />
            </span>

            <bxs-spacer v-if="spacer && appendIcon"></bxs-spacer>

            <bxs-icon
            v-if="appendIcon"
            :name="appendIcon"
            :disabled="disabled"
            class="bxs-list-item-icon-append" />
        </router-link>

        <a
        v-else-if="href"
        :href="href"
        :target="target"
        :exact="exact"
        :disabled="disabled"
        class="bxs-list-item--content">
            <bxs-icon
            v-if="prependIcon"
            :name="prependIcon"
            :disabled="disabled"
            class="bxs-list-item-icon-prepend" />

            <bxs-spacer v-if="spacer && prependIcon"></bxs-spacer>

            <slot />

            <bxs-spacer v-if="spacer && appendIcon"></bxs-spacer>

            <bxs-icon
            v-if="appendIcon"
            :name="appendIcon"
            :disabled="disabled"
            class="bxs-list-item-icon-append" />
        </a>

        <div
        v-else
        class="bxs-list-item--content">
            <bxs-icon
            v-if="prependIcon"
            :name="prependIcon"
            :disabled="disabled"
            class="bxs-list-item-icon-prepend" />

            <bxs-spacer v-if="spacer && prependIcon"></bxs-spacer>

            <slot />

            <bxs-spacer v-if="spacer && appendIcon"></bxs-spacer>

            <bxs-icon
            v-if="appendIcon"
            :name="appendIcon"
            :disabled="disabled"
            class="bxs-list-item-icon-append" />
        </div>
    </li>
</template>

<script>
import { colorable } from '@/mixins'

export default {
    name: 'bxs-list-item',
    mixins: [colorable],
    props: {
        to: {
            type: String,
            required: false,
            default: null
        },
        link: {
            type: Boolean,
            required: false,
            default: false
        },
        exact: {
            type: Boolean,
            required: false,
            default: false
        },
        'prepend-icon': {
            type: String,
            required: false,
            default: null
        },
        'append-icon': {
            type: String,
            required: false,
            default: null
        },
        href: {
            type: String,
            required: false,
            default: null
        },
        target: {
            type: String,
            required: false,
            default: '_blank'
        },
        spacer: {
            type: Boolean,
            required: false,
            default: false
        },
        tile: {
            type: Boolean,
            required: false,
            default: false
        },
        truncate: {
            type: Boolean,
            required: false,
            default: false
        },
        collapse: {
            type: Boolean,
            required: false,
            default: false
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    computed: {
        classes () {
            return ['bxs-list-item', {
                'bxs-list-item-to': !!this.to,
                'bxs-list-item-link': this.link || !!this.to || !!this.href || 'onClick' in this.$attrs,
                'bxs-list-item-prepend-icon': this.prependIcon,
                'bxs-list-item-append-icon': this.appendIcon,
                'bxs-list-item-disabled': !!this.disabled,
                'bxs-disabled': !!this.disabled,
                'bxs-tile': this.tile,
                'bxs-truncate': this.truncate,
                ...this.colorable.classes
            }]
        }
    }
    // mounted () {
    //     console.log(this)
    // }
}
</script>

<style lang="scss" scoped>
.bxs-list-item {
    flex: 1;
    width: 100%;
    // height: 100%;
    min-height: var(--list-item-min-height);
    font-size: var(--list-item-font-size);
    background-color: var(--list-item-background-color);
    border-radius: var(--list-item-border-radius);
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    overflow: hidden;
    font-weight: var(--list-item-font-weight);
    text-align: left;
    text-transform: var(--list-item-text-transform);
    border-bottom: 1px solid var(--list-item-border-bottom-color);

    @media (hover: hover) and (pointer: fine) {
        &:hover {
            background-image: linear-gradient(rgb(0 0 0/5%) 0 0);
        }
    }

    // &:hover {
    //     &.bxs-list-item-link,
    //     &.bxs-list-item-to {
    //         // background-color: var(--list-item-hover-background-color);
    //     }
    // }

    &.bxs-list-item-link,
    &.bxs-list-item-to {
        padding-left: var(--list-item-padding-x);
        padding-right: var(--list-item-padding-x);
        cursor: pointer;
        pointer-events: auto;
    }

    &.bxs-list-item-disabled {
        color: var(--color-disabled);
    }

    &--content {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        flex: 1;
        min-height: var(--list-item-min-height);

        font-size: inherit !important;
        font-weight: inherit !important;
        text-decoration: none;

        width: 100%;
        height: 100%;
        padding-top: var(--list-item-padding-y);
        padding-bottom: var(--list-item-padding-y);

        // transition: padding 0.15s ease-out;

        &::before {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 0;
            background-color: var(--button-color);
        }

        &.router-link-active {
            &::before {
                height: var(--thickness);
            }
        }
    }

    .bxs-icon {
        width: var(--icon-nest-size) !important;
        height: var(--icon-nest-size) !important;

        &.bxs-list-item-icon-prepend {
            margin-right: var(--list-item-icon-margin-x);
        }

        &.bxs-list-item-icon-append {
            margin-left: var(--list-item-icon-margin-x);
        }
    }
}
</style>