<template>
    <div class="checkout-loan-product-view">
        <bxs-top-nav>
            <span>Prestito</span>

            <template #append>
                <bxs-menu icon>
                    <bxs-list>
                        <bxs-list-item
                        append-icon="close"
                        spacer
                        @click="clearCart">Pulisci il checkout e riparti</bxs-list-item>
                    </bxs-list>
                </bxs-menu>
            </template>
        </bxs-top-nav>

        <!-- date picker -->
        <section>
            <bxs-layout>
                <div class="mb-ui">
                    <bxs-title>Periodo di prestito</bxs-title>
                    <!-- <p class="text-mute">Il tempo di prestito massimo è di 30 giorni.</p> -->
                </div>

                <!-- <bxs-loader
                v-if="calendar_loading"
                label="Caricamento"></bxs-loader> -->

                <div
                v-if="$leila.checkout.shipping.address.id !== 'delivery'"
                class="flex align-center">
                    <bxs-chip
                    width="100%"
                    :loading="calendar_loading"
                    tile>{{ $filters.toHumanDate($leila.checkout.loan.from_date) }}</bxs-chip>

                    <bxs-spacer />

                    <bxs-icon
                    width="60"
                    name="arrow-right"
                    class="mx-6"></bxs-icon>

                    <bxs-spacer />

                    <bxs-chip
                    width="100%"
                    :loading="calendar_loading"
                    tile>{{ $filters.toHumanDate($leila.checkout.loan.to_date) }}</bxs-chip>
                </div>

                <div v-else>
                    <bxs-alert>Il periodo di prestito verrà calcolato in base alla data della consegna</bxs-alert>
                </div>
            </bxs-layout>
        </section>

        <!-- pickup -->
        <section>
            <bxs-layout>
                <div class="mb-ui">
                    <bxs-title>Scegli il corner di ritiro</bxs-title>
                </div>

                <bxs-data-lazy
                v-model="headquarters"
                :call="() => $leila.get('v1/headquarters')">
                    <bxs-card class="pa-ui">
                        <headquarter-row
                        v-for="item in headquarters"
                        :key="item.id"
                        :class="['mb-ui', 'bxs-pointer', { 'bxs-disabled': item.id === $leila.checkout.shipping.address.id }]"
                        :item="item"
                        @click="setPickup(item)">
                            <bxs-alert
                            v-if="item.id === 'delivery'"
                            class="mb-ui">Direttamente a casa tua con un contribuito di 5,00 €. Trovi le istruzioni per il contibuito richiesto nella prossima schermata.</bxs-alert>
                            <!-- <p v-if="item.id === 'delivery'">Direttamente a casa tua con un contribuito di 5,00 €. Le istruzioni per il contibuito richiesto nella prossima schermata.</p> -->

                            <template #actions>
                                <bxs-icon
                                v-if="item.id === $leila.checkout.shipping.address.id"
                                text-color="primary"
                                width="20"
                                height="20">
                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                                </bxs-icon>

                                <bxs-icon
                                v-else
                                width="20"
                                height="20">
                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                </bxs-icon>
                            </template>
                        </headquarter-row>
                    </bxs-card>
                </bxs-data-lazy>

                <div
                v-if="product && $leila.checkout.shipping.method !== 'delivery'"
                class="mt-ui">
                    <p class="mb-0">Ci {{ product.loan.preparation_days <= 1 ? 'vuole' : 'vogliono' }} <b>{{ product.loan.preparation_days }} gg</b> per preparare l'oggetto per il ritiro<span v-if="$leila.checkout.loan.from_date">, quindi puoi venire a prenderlo <b>a partire dal {{ $filters.toHumanDate($leila.checkout.loan.from_date) }}</b></span><span v-else-if="selected_date.start">, quindi puoi venire a prenderlo <b>a partire dal {{ $filters.toHumanDate(selected_date.start) }}</b></span>.</p>
                </div>
            </bxs-layout>
        </section>

        <section>
            <bxs-layout>
                <div class="mb-ui">
                    <bxs-title>Hai delle domande?</bxs-title>
                    <p>Specifica tutto in questo spazio, ti risponderemo appena possibile.</p>
                </div>

                <div>
                    <bxs-textarea-field
                    v-model="$leila.checkout.note"
                    solo
                    label="Inserisci qui le tue domande!"
                    name="note" />
                </div>
            </bxs-layout>
        </section>

        <!--  -->
        <pressing-section />

        <section>
            <bxs-layout>
                <bxs-btn
                append-icon="close"
                between
                block
                color="card"
                uppercase
                @click="clearCart">Pulisci il checkout e riparti</bxs-btn>
            </bxs-layout>
        </section>

        <!-- help -->
        <help-section />

        <!-- checkoutbar -->
        <!-- :disabled="!is_date_selected" -->
        <checkoutbar
        :loading="next_loading"
        @click:next="next" />
    </div>
</template>

<script>
// import { DatePicker } from 'v-calendar'
// import 'v-calendar/dist/style.css'
import moment from 'moment'

moment.locale('it')

export default {
    name: 'checkout_loan_product',
    // components: {
    //     DatePicker
    // },
    data () {
        return {
            calendar_loading: true,
            headquarters: [],
            categories: [],
            product: null,
            product_loans: [],

            // modelConfig: {
            //     type: 'string',
            //     mask: 'DD/MM/YYYY hh:ss' // Uses 'iso' if missing
            // },
            next_loading: false,
            selected_date: {
                start: null,
                end: null
            },
            min_date: null,
            max_date: null,
            disabled_dates: [],
            date_key: 1,
            timezone: null
        }
    },
    computed: {
        is_date_selected () {
            return !!this.selected_date.start && !!this.selected_date.end
        }
    },
    mounted () {
        this.$nextTick(this.start)
    },
    methods: {
        async start () {
            const checkout = await this.$leila.checkout.get()
            this.product = await this.$leila.catalogs.getProduct(checkout.product.id)

            // console.log('CheckoutLoanProduct $leila', this.$leila)

            // this.product_loans = await this.$leila.catalogs.getProductLoans(checkout.product.id)

            if (checkout.loan.from_date) {
                this.selected_date.start = new Date(checkout.loan.from_date)
            }

            if (checkout.loan.to_date) {
                this.selected_date.end = new Date(checkout.loan.to_date)
            }

            // if (this.product_loans.length > 0) {
            //     this.product_loans.forEach((item) => {
            //         this.disabled_dates.push({
            //             start: item.loan.from_date,
            //             end: item.loan.to_date
            //         })
            //     })
            // }

            // this.min_date = moment().startOf('day').add(this.product.loan.preparation_days, 'days').endOf('day').toDate()
            // this.max_date = moment().startOf('day').add(this.product.loan.max_months, 'month').endOf('day').toDate()
            // this.selected_date.start = this.min_date
            // this.selected_date.end = this.max_date

            // this.disabled_dates.unshift({
            //     start: new Date(),
            //     end: new Date()
            // })

            // this.date_key++

            if (this.$store.state.library && this.$store.state.library.delivery.on) {
                this.headquarters.unshift({
                    id: 'delivery',
                    name: 'Consegna a domicilio',
                    address: 'Direttamente a casa tua!',
                    img: { url: null, alt: null }
                })
            }

            this.calendar_loading = false
        },
        handleInputDate () {
            if (this.selected_date.start && this.selected_date.end) {
                const a = moment(this.selected_date.start).startOf('day')
                const b = moment(this.selected_date.end).startOf('day')
                const days = Math.abs(a.diff(b, 'days')) + 1
                console.log('days', days)

                if (days >= 31) {
                    this.selected_date.start = null
                    this.selected_date.end = null
                    this.$toast.error('Tempo di prestito massimo è di 30 giorni!')
                    this.date_key++
                }
            }
        },
        async setPickup (item) {
            this.next_loading = true

            try {
                const doc = await this.$leila.checkout.setShippng(
                    item.id === 'delivery' ? 'delivery' : 'pickup',
                    item.id
                )

                this.selected_date.start = new Date(doc.loan.from_date)
                this.selected_date.end = new Date(doc.loan.to_date)
            } catch (err) {
                throw new Error(err.message || err.raw || err)
            } finally {
                this.next_loading = false
            }
        },
        async next () {
            if (!this.is_date_selected) {
                return this.$toast.warning('Seleziona la data del prestito!')
            }

            this.next_loading = true

            try {
                await this.$leila.checkout.setLoanDate(
                    this.selected_date.start,
                    this.selected_date.end,
                    this.$leila.checkout.note
                )
                this.$router.push('/checkout/recap')
            } catch (err) {
                throw new Error(err.message || err.raw || err)
            } finally {
                this.next_loading = false
            }
        },
        async clearCart () {
            this.$store.commit('set_is_loading', true)
            await this.$leila.checkout.clear()
            this.$router.go(-1)
            this.$store.commit('set_is_loading', false)
        }
    }
}
</script>