<template>
    <div class="help-view">
        <bxs-top-nav>Contatti</bxs-top-nav>

        <!--  -->
        <section>
            <bxs-layout>
                <bxs-title>Contatti</bxs-title>

                <bxs-list>
                    <bxs-list-item
                    to="/support"
                    spacer
                    append-icon="chevron-right">Supporto</bxs-list-item>

                    <bxs-list-item
                    v-if="$store.state.library.contacts.phone"
                    :href="`tel:${$store.state.library.contacts.phone}`"
                    spacer
                    append-icon="telephone-fill">{{ $store.state.library.contacts.phone }}</bxs-list-item>

                    <bxs-list-item
                    v-if="$store.state.library.contacts.email"
                    :href="`mailto:${$store.state.library.contacts.email}`"
                    spacer
                    append-icon="envelope">{{ $store.state.library.contacts.email }}</bxs-list-item>

                    <bxs-list-item
                    v-if="$store.state.library.contacts.whatsapp"
                    :href="$store.state.library.contacts.whatsapp"
                    spacer
                    append-icon="wa">WhatsApp</bxs-list-item>

                    <bxs-list-item
                    v-if="$store.state.library.contacts.telegram"
                    :href="$store.state.library.contacts.telegram"
                    spacer
                    append-icon="tg">Telegram</bxs-list-item>
                </bxs-list>
            </bxs-layout>
        </section>

        <section>
            <bxs-layout>
                <bxs-title>Link utili</bxs-title>

                <bxs-list>
                    <bxs-list-item
                    v-if="$store.state.library.website"
                    append-icon="arrow-top-right"
                    spacer
                    :href="$store.state.library.website">Visita il nostro sito</bxs-list-item>

                    <bxs-list-item
                    v-if="$store.state.library.links && $store.state.library.links.how_work"
                    :href="$store.state.library.links.how_work"
                    spacer
                    append-icon="arrow-up-right">Come funziona</bxs-list-item>

                    <bxs-list-item
                    v-if="$store.state.library.links && $store.state.library.links.membership"
                    :href="$store.state.library.links.membership"
                    spacer
                    append-icon="arrow-up-right">Iscrizioni</bxs-list-item>

                    <bxs-list-item
                    v-if="$store.state.library.links && $store.state.library.links.contact"
                    :href="$store.state.library.links.contact"
                    spacer
                    append-icon="arrow-up-right">Contatti e assistenza</bxs-list-item>
                </bxs-list>
            </bxs-layout>
        </section>

        <section>
            <bxs-layout>
                <bxs-title>Community</bxs-title>

                <bxs-list>
                    <bxs-list-item
                    href="https://leila-bologna.it"
                    spacer
                    append-icon="arrow-up-right">Collaborazioni</bxs-list-item>

                    <bxs-list-item
                    href="https://leila-bologna.it"
                    spacer
                    append-icon="arrow-up-right">Pubblicità</bxs-list-item>
                </bxs-list>
            </bxs-layout>
        </section>

        <section>
            <bxs-layout>
                <bxs-footer />
            </bxs-layout>
        </section>
    </div>
</template>

<script>
export default {
    name: 'help'
}
</script>