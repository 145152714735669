<template>
    <div class="checkout-cb-view">
        <bxs-top-nav hide-undo>Prenotazione completata</bxs-top-nav>

        <bxs-data-lazy
        v-model="data_page"
        :call="() => $leila.me.getOrder($route.params.id)"
        class="mt-ui">
            <section>
                <bxs-layout>
                    <div class="mb-6">
                        <h4 class="mb-2">Grazie per la tua prenotazione!</h4>
                        <p>Riceverai una mail di conferma con le istruzioni, controlla nella cartella spam se non si riceve nessuna comunicazione.</p>
                    </div>

                    <div
                    v-if="data_page.shipping.method !== 'delivery'"
                    class="mb-6">
                        <div class="mb-ui">
                            <p>Vieni a ritirare il tuo oggetto <b>a partire dal {{ $filters.toHumanDate(data_page.loan.from_date, 'L') }}</b></p>
                        </div>

                        <div class="flex align-center">
                            <bxs-btn width="100%">{{ $filters.toHumanDate(data_page.loan.from_date) }}</bxs-btn>

                            <bxs-spacer />

                            <bxs-icon
                            width="60"
                            name="arrow-right"
                            class="mx-12"></bxs-icon>

                            <bxs-spacer />

                            <bxs-btn width="100%">{{ $filters.toHumanDate(data_page.loan.to_date) }}</bxs-btn>
                        </div>
                    </div>

                    <div
                    v-else
                    class="mb-6">
                        <bxs-alert class="mb-ui">Il periodo di prestito verrà calcolato e concordato in base alla data della consegna.</bxs-alert>

                        <bxs-btn
                        min-height="50"
                        block
                        uppercase
                        color="primary"
                        light
                        class="mx-auto"
                        @click="toPaypal"><span class="text-500">Clicca il bottone per versare il contribuito di 5,00 € per la consegna a domicilio</span></bxs-btn>
                    </div>

                    <div class="mb-6">
                        <p v-if="data_page.shipping.method !== 'delivery'">L'oggetto dovrà essere restituito presso il corner dov'è stato ritirato, se hai necessità diverse <router-link to="/help">contattaci</router-link>.</p>
                        <p v-else>L'oggetto dovrà essere restituito presso il corner di appartenenza o concordato, se hai necessità diverse, <router-link to="/help">contattaci</router-link>.</p>

                        <headquarter-row
                        :item="data_page.shipping.address"
                        class="my-2" />
                    </div>

                    <div>
                        <p
                        v-if="data_page.shipping.method !== 'delivery'"
                        class="mb-ui">Se hai bisogno del trasporto o di ritirarlo con più urgenza <router-link to="/help">contattaci</router-link> che troviamo una soluzione.</p>
                        <p>Ovviamente non c'è bisogno di attendere la scadenza del prestito per restituire l'oggetto, gli altri soci non potranno che esserne felici.</p>
                    </div>
                </bxs-layout>
            </section>

            <div class="bxs-divider"></div>

            <section>
                <bxs-layout>
                    <bxs-list>
                        <bxs-list-item
                        append-icon="arrow-right"
                        spacer
                        to="/help">Aiuto e contatti</bxs-list-item>

                        <bxs-list-item
                        append-icon="arrow-right"
                        spacer
                        to="/me">Il mio profilo</bxs-list-item>

                        <bxs-list-item
                        append-icon="arrow-right"
                        spacer
                        to="/">Riparti</bxs-list-item>
                    </bxs-list>
                </bxs-layout>
            </section>
        </bxs-data-lazy>
    </div>
</template>

<script>
export default {
    name: 'checkout_cb',
    data () {
        return {
            data_page: null
        }
    },
    methods: {
        toPaypal () {
            window.open('https://www.paypal.com/paypalme/leilabologna/5', '_blank')
        }
    }
}
</script>