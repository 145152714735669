<template>
    <div class="checkout-recap-view">
        <bxs-top-nav :sticky="false">
            <span>Recap prestito</span>

            <template #append>
                <bxs-btn
                v-if="!$store.state.is_mobile"
                :loading="loading"
                :disabled="loading"
                uppercase
                color="primary"
                light
                @click="confirm"><span class="text-500 small">Conferma e invia prenotazione</span></bxs-btn>
            </template>
        </bxs-top-nav>

        <section v-if="error">
            <bxs-layout>
                <bxs-alert type="error">{{ error }}</bxs-alert>
            </bxs-layout>
        </section>

        <bxs-data-lazy
        v-model="data_page"
        :call="() => $leila.checkout.get()">
            <!-- <section v-sticky>
                <bxs-layout>
                    <div
                    v-if="error"
                    class="flex nowrap align-start mb-ui error light pa-ui"
                    style="border-radius: var(--radius);">
                        <bxs-icon
                        name="exclamation-triangle-fill"
                        width="5%"
                        class="mr-ui"></bxs-icon>
                        <div style="width: 95%">{{ error }}</div>
                    </div>

                    <bxs-btn
                    min-height="60"
                    block
                    :loading="loading"
                    :disabled="loading"
                    uppercase
                    color="primary"
                    light
                    @click="confirm">Conferma e invia prenotazione</bxs-btn>
                </bxs-layout>
            </section> -->

            <section>
                <bxs-layout>
                    <bxs-title>Oggetto</bxs-title>

                    <bxs-card class="pa-ui">
                        <!-- <bxs-row align="center">
                            <bxs-col cols="2">
                                <bxs-figure
                                ratio="1"
                                :src="$filters.toStaticFile(data_page.product.img.url)"></bxs-figure>
                            </bxs-col>

                            <bxs-col
                            cols="10"
                            class="pl-ui">
                                <p class="text-ui h6">{{ data_page.product.name }}</p>
                            </bxs-col>
                        </bxs-row> -->

                        <product-row
                        :item="data_page.product"
                        hide-actions
                        style="pointer-events: none;" />
                    </bxs-card>
                </bxs-layout>
            </section>

            <section>
                <bxs-layout>
                    <div class="mb-ui">
                        <bxs-title>Periodo di prestito</bxs-title>
                        <p v-if="$leila.checkout.shipping.address.id !== 'delivery'">Vieni a ritirare il tuo oggetto a partire <b> dal {{ $filters.toHumanDate($leila.checkout.loan.from_date, 'L') }}</b></p>
                    </div>

                    <div
                    v-if="$leila.checkout.shipping.address.id !== 'delivery'"
                    class="flex align-center">
                        <bxs-chip
                        width="100%"
                        tile>{{ $filters.toHumanDate($leila.checkout.loan.from_date) }}</bxs-chip>

                        <bxs-spacer></bxs-spacer>

                        <bxs-icon
                        width="60"
                        name="arrow-right"
                        class="mx-6"></bxs-icon>

                        <bxs-spacer></bxs-spacer>

                        <bxs-chip
                        width="100%"
                        tile>{{ $filters.toHumanDate($leila.checkout.loan.to_date) }}</bxs-chip>
                    </div>

                    <div v-else>
                        <bxs-alert>Direttamente a casa tua con un contribuito di 5,00 € <br> Il periodo di prestito verrà calcolato e concordato in base alla data della consegna. <br><br> Conferma la prenotazione per essere reindirizzat* sulla pagina PayPal.</bxs-alert>
                    </div>

                    <!-- <bxs-card class="pa-ui">
                        <div class="flex mb-1">
                            <p>Dal</p>
                            <bxs-spacer></bxs-spacer>
                            <p><b>{{ $filters.toHumanDate(data_page.loan.from_date, 'L') }}</b></p>
                        </div>

                        <div class="flex">
                            <p>Al</p>
                            <bxs-spacer></bxs-spacer>
                            <p><b>{{ $filters.toHumanDate(data_page.loan.to_date, 'L') }}</b></p>
                        </div>
                    </bxs-card> -->
                </bxs-layout>
            </section>

            <section>
                <bxs-layout>
                    <div v-if="$leila.checkout.shipping.address.id !== 'delivery'">
                        <div class="mb-ui">
                            <bxs-title>Corner di ritiro</bxs-title>
                            <p>L'oggetto dovrà essere restituito presso il corner dov'è stato ritirato, se hai necessità diverse <router-link to="/help">contattaci</router-link>.</p>
                        </div>

                        <bxs-card class="pa-ui">
                            <headquarter-row
                            v-if="$leila.checkout.shipping.address.id !== 'delivery'"
                            :item="data_page.shipping.address" />
                        </bxs-card>
                    </div>

                    <div
                    v-else
                    class="mb-ui">
                        <div class="mb-ui">
                            <bxs-title>Corner di riconsegna</bxs-title>
                            <p>L'oggetto dovrà essere restituito presso il corner di appartenenza o concordato, se hai necessità diverse, <router-link to="/help">contattaci</router-link>.</p>
                        </div>

                        <bxs-card class="pa-ui">
                            <headquarter-row :item="data_page.product.location.address" />
                        </bxs-card>
                    </div>
                </bxs-layout>
            </section>

            <section v-if="data_page.note">
                <bxs-layout>
                    <bxs-title>Note</bxs-title>

                    <bxs-card class="pa-ui">
                        <div v-html="$filters.mdToHtml(data_page.note)"></div>
                    </bxs-card>
                </bxs-layout>
            </section>

            <!-- <section>
                <bxs-layout>
                    <div
                    v-if="error"
                    class="flex nowrap align-start mb-ui error light pa-ui"
                    style="border-radius: var(--radius);">
                        <bxs-icon
                        name="exclamation-triangle-fill"
                        width="5%"
                        class="mr-ui"></bxs-icon>
                        <div style="width: 95%">{{ error }}</div>
                    </div>

                    <bxs-btn
                    min-height="60"
                    block
                    :loading="loading"
                    :disabled="loading"
                    uppercase
                    color="primary"
                    light
                    @click="confirm">Conferma e invia prenotazione</bxs-btn>
                </bxs-layout>
            </section> -->

            <section class="text-center">
                <bxs-layout>
                    <small>Ovviamente non c'è bisogno di attendere la scadenza del prestito <br v-if="!$store.state.is_mobile"> per restituire l'oggetto, gli altri soci non potranno che esserne felici.</small>
                </bxs-layout>
            </section>
        </bxs-data-lazy>

        <bxs-bottom-nav :bottom="$store.state.is_mobile ? 60 : 0">
            <bxs-btn
            min-height="50"
            block
            max-width="320"
            :loading="loading"
            :disabled="loading"
            uppercase
            color="primary"
            light
            class="mx-auto"
            @click="confirm"><span class="text-500">Conferma e invia prenotazione</span></bxs-btn>
        </bxs-bottom-nav>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: 'checkout_recap',
    computed: {
        ...mapState({
            checkout: state => state.checkout
        })
    },
    data () {
        return {
            data_page: null,
            loading: false,
            error: null
        }
    },
    mounted () {
        // this.paypal().load()
    },
    methods: {
        // paypal () {
        //     return {
        //         load: () => {
        //             return new Promise((resolve, reject) => {
        //                 const script = document.createElement('script')
        //                 script.setAttribute('data-sdk-integration-source', 'button-factory')
        //                 const pk = 'AYnxDTg2uf3HttOtKB2VD_McDWyzTncv4Ij5uMCh5Yl2RkX006rhkaO28f7YucFB_3xoL0l_yybD0wd8'
        //                 // const query_components = '&components=buttons'
        //                 const query = '?client-id=' + pk + '&currency=EUR&disable-funding=card,credit,bancontact,blik,eps,giropay,ideal,mybank,p24,sepa,sofort,venmo'
        //                 script.src = 'https://www.paypal.com/sdk/js' + query
        //                 // data-sdk-integration-source="button-factory"
        //                 // script.setAttribute('data-sdk-integration-source', 'button-factory')
        //                 script.addEventListener('load', resolve)
        //                 document.body.appendChild(script)
        //             })
        //         },
        //         init: () => {
        //         }
        //     }
        // },
        async confirm () {
            this.error = null
            this.loading = true
            this.$store.commit('set_is_loading', true)

            try {
                const { id } = await this.$leila.checkout.confirm()
                this.$leila.checkout.get()
                this.$router.push('/checkout/' + id + '/cb')
            } catch (err) {
                throw new Error('Ops, al momento non è possibile effettuare la prenotazione, riprova più tardi!')
                // this.error = err.message || err.name || err
            } finally {
                this.loading = false
                this.$store.commit('set_is_loading', false)
            }
        }
    }
}
</script>