<template>
    <!-- <bxs-data-lazy :call="() => $leila.get('v1/categories')">
        <template #data="{ data }">
        </template>
    </bxs-data-lazy> -->

    <section v-if="products_categories.length > 0">
        <bxs-layout>
            <div class="mb-ui">
                <bxs-title>Cerca tra le categorie</bxs-title>
            </div>

            <bxs-row>
                <bxs-col
                v-for="(item, i) in products_categories"
                :key="i"
                cols="6"
                ml="4">
                    <bxs-card
                    :to="`/catalogs/${item.slug}`"
                    height="50">
                        <div class="flex nowrap align-center">
                            <img
                            v-if="item.slug !== 'all'"
                            :src="item.slug === 'in-prestito' ? '/img/categories/in-prestito.svg' : $filters.toStaticFileMaster(item.img.url)"
                            :alt="`Leila biblioteca degli oggetti ${$store.state.library ? $store.state.library.name : ''} categoria ${item.name}`"
                            style="width: 50px; height: 50px;">

                            <img
                            v-else
                            :src="'/img/tutti-gli-oggetti-icona.png'"
                            alt="Leila biblioteca degli oggetti tutti gli oggetti"
                            style="width: 50px; height: 50px;">

                            <div class="flex-1 px-ui">
                                <p class="text-500">{{ item.name }}</p>
                            </div>
                        </div>
                    </bxs-card>
                </bxs-col>
            </bxs-row>
        </bxs-layout>
    </section>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: 'categories_2_section',
    computed: {
        ...mapState({
            products_categories: state => state.products_categories
        })
    }
}
</script>