<template>
    <div class="password-reset-view">
        <bxs-top-nav>Reset password</bxs-top-nav>

        <section>
            <bxs-layout>
                <div
                v-if="!user"
                class="mb-ui">
                    <p>Inserisci il codice ricevuto via email</p>
                </div>

                <!-- --------------------------------------------- -->
                <bxs-form
                v-if="!user"
                ref="form_code"
                class="mb-ui"
                @submit="verifyCode">
                    <div class="flex nowrap mb-grid-gutter">
                        <bxs-text-field
                        v-model="code.n_1"
                        name="code_1"
                        required
                        max-length="1"
                        hide-counter
                        class="mr-2" />

                        <bxs-text-field
                        v-model="code.n_2"
                        name="code_2"
                        required
                        max-length="1"
                        hide-counter
                        min-length="1"
                        class="mr-2" />

                        <bxs-text-field
                        v-model="code.n_3"
                        name="code_3"
                        required
                        max-length="1"
                        hide-counter
                        class="mr-2" />

                        <bxs-text-field
                        v-model="code.n_4"
                        name="code_4"
                        required
                        max-length="1"
                        hide-counter
                        class="mr-2" />

                        <bxs-text-field
                        v-model="code.n_5"
                        name="code_5"
                        hide-counter
                        max-length="1"
                        required />
                    </div>

                    <bxs-btn
                    block
                    color="primary"
                    light
                    :disabled="loading"
                    :loading="loading"
                    @click="$refs.form_code.submit()">Verifica</bxs-btn>
                </bxs-form>

                <!-- --------------------------------------------- -->
                <bxs-form
                v-else-if="user && !success"
                ref="form_pwd_reset"
                class="mb-ui"
                @submit="resetPassword">
                    <bxs-text-field
                    v-model="password"
                    required
                    label="Password"
                    type="password"
                    name="password"
                    class="mb-grid-gutter">
                        <template #append="{ on, value }">
                            <bxs-btn
                            v-on="on"
                            small>{{ !value ? 'show' : 'hide' }}</bxs-btn>
                        </template>
                    </bxs-text-field>

                    <bxs-text-field
                    v-model="confirm_password"
                    required
                    label="Conferma password"
                    type="password"
                    name="confirm_password"
                    class="mb-grid-gutter">
                        <template #append="{ on, value }">
                            <bxs-btn
                            v-on="on"
                            small>{{ !value ? 'show' : 'hide' }}</bxs-btn>
                        </template>
                    </bxs-text-field>

                    <bxs-btn
                    block
                    color="primary"
                    light
                    :disabled="loading"
                    :loading="loading"
                    @click="$refs.form_pwd_reset.submit()">Cambia password</bxs-btn>
                </bxs-form>

                <div
                v-else
                class="mb-ui text-center">
                    <h4>Passsword aggiornata!</h4>
                </div>

                <!-- --------------------------------------------- -->
                <bxs-list>
                    <bxs-list v-if="!user">
                        <bxs-list-item
                        append-icon="chevron-right"
                        spacer
                        to="/password/recovery">Non hai ricevuto il codice via email? Richiedi</bxs-list-item>
                    </bxs-list>

                    <bxs-list v-if="!$store.state.access">
                        <bxs-list-item
                        append-icon="chevron-right"
                        spacer
                        to="/signin">Hai già un account? Accedi</bxs-list-item>
                    </bxs-list>

                    <bxs-list v-else>
                        <bxs-list-item
                        append-icon="chevron-right"
                        spacer
                        to="/me">Il mio profilo</bxs-list-item>
                    </bxs-list>
                </bxs-list>
            </bxs-layout>
        </section>
    </div>
</template>

<script>
export default {
    name: 'password_reset',
    data () {
        return {
            password: null,
            confirm_password: null,
            code: {
                n_1: null,
                n_2: null,
                n_3: null,
                n_4: null,
                n_5: null
            },
            // code: null,
            user: null,
            success: false,

            error_reset: null,
            error_code: null,
            loading: false
        }
    },
    mounted () {
        this.$nextTick(this.start)
    },
    methods: {
        start () {
            this.$el.addEventListener('paste', (evt) => {
                evt.stopPropagation()
                evt.preventDefault()

                const clipboardData = evt.clipboardData || window.clipboardData
                const pastedData = clipboardData.getData('Text')

                if (pastedData && pastedData.length === 5) {
                    this.code = {
                        n_1: pastedData[0],
                        n_2: pastedData[1],
                        n_3: pastedData[2],
                        n_4: pastedData[3],
                        n_5: pastedData[4]
                    }

                    // this.$toast.success('Codice incollato')
                }
            })
        },
        stop () {
            if (this.$el) {
                this.$el.removeEventListener('paste')
            }
        },
        async verifyCode () {
            this.loading = true

            try {
                this.user = await this.$http.get('v1/auths/password_recovery', {
                    code: Object.values(this.code).join('')
                })
            } catch (err) {
                if (err.status_code === 404) {
                    throw new Error('Ops, inserisci il codice corretto')
                }

                throw new Error(err.message || err.raw)
            } finally {
                this.loading = false
            }
        },
        async resetPassword () {
            this.loading = true

            try {
                await this.$http.patch('v1/auths/password_recovery', {
                    code: Object.values(this.code).join(''),
                    password: this.password,
                    confirm_password: this.confirm_password
                })

                this.success = true
            } catch (err) {
                throw new Error(err.message || err.raw)
            } finally {
                this.loading = false
            }
        }
    },
    beforeUnmount() {
        this.stop()
    }
}
</script>