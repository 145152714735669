<template>
    <div class="me-orders-view">
        <bxs-top-nav>I tuoi prestiti</bxs-top-nav>

        <bxs-data-lazy
        v-model="data_page"
        :call="() => $leila.me.getOrders()">
            <section>
                <bxs-layout>
                    <div v-if="!data_page.length">
                        <div class="text-center mb-ui">
                            <h3>Ancora nessun prestito</h3>
                            <p>In questa sezione troverai lo storico dei tuoi prestiti</p>
                        </div>
                    </div>

                    <div v-else>
                        <div class="mb-6">
                            <bxs-chip-group>
                                <bxs-chip
                                v-if="$leila.env === 'development'"
                                disabled
                                class="mr-ui">{{ orders_count }} prestiti totali</bxs-chip>

                                <bxs-chip
                                v-if="computed_orders.pending.length > 0"
                                link
                                outlined
                                :color="filter === 'pending' ? 'secondary' : null"
                                class="mr-ui"
                                @click="filter = 'pending'">Pending</bxs-chip>

                                <bxs-chip
                                v-if="computed_orders.approved.length > 0"
                                :color="filter === 'approved' ? 'secondary' : null"
                                link
                                outlined
                                class="mr-ui"
                                @click="filter = 'approved'">Approvati</bxs-chip>

                                <bxs-chip
                                v-if="computed_orders.extended.length > 0"
                                :color="filter === 'extended' ? 'secondary' : null"
                                link
                                outlined
                                class="mr-ui"
                                @click="filter = 'extended'">Estesi</bxs-chip>

                                <bxs-chip
                                v-if="computed_orders.returned.length > 0"
                                :color="filter === 'returned' ? 'secondary' : null"
                                link
                                outlined
                                class="mr-ui"
                                @click="filter = 'returned'">Restituiti</bxs-chip>

                                <bxs-chip
                                v-if="computed_orders.cancelled.length > 0"
                                :color="filter === 'cancelled' ? 'secondary' : null"
                                link
                                outlined
                                class="mr-ui"
                                @click="filter = 'cancelled'">Annullati</bxs-chip>

                                <bxs-chip
                                v-if="computed_orders.denied.length > 0"
                                :color="filter === 'denied' ? 'secondary' : null"
                                link
                                outlined
                                class="mr-ui"
                                @click="filter = 'denied'">Negati</bxs-chip>
                            </bxs-chip-group>

                            <div
                            v-if="filter !== null"
                            class="mt-2">
                                <small
                                class="text-uppercase text-mute bxs-pointer"
                                @click="filter = null">
                                    <span >Pulisci filtri</span>
                                    <bxs-icon name="close" class="ml-2"></bxs-icon>
                                </small>
                            </div>
                        </div>

                        <!-- ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
                        ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ -->
                        <div
                        v-if="computed_orders.pending.length > 0"
                        class="mb-ui">
                            <div class="mb-ui">
                                <h5>Pending</h5>
                            </div>

                            <ul>
                                <li
                                v-for="item in computed_orders.pending"
                                :key="item.id"
                                class="mb-ui">
                                    <bxs-card class="pa-ui">
                                        <bxs-row>
                                            <bxs-col cols="2">
                                                <bxs-figure
                                                ratio="1"
                                                :src="$filters.toStaticFile(item.product.img.url)"
                                                max-width="320"
                                                class="bxs-pointer mb-ui mx-auto"
                                                @click="$router.push('/products/' + item.product.id)"></bxs-figure>
                                            </bxs-col>

                                            <bxs-col
                                            cols="10"
                                            class="pl-ui">
                                                <!-- <div
                                                v-if="item.library"
                                                class="mb-ui">
                                                    <img
                                                    :src="$filters.toStaticFile(item.library.logo)"
                                                    :alt="`${$store.state.library ? $store.state.library.name : ''} logo`"
                                                    style="max-width: 48px;">
                                                </div> -->

                                                <div class="flex nowrap">
                                                    <div class="flex-1">
                                                        <div class="bxs-truncated mb-ui">
                                                            <h6
                                                            v-html="item.product.name"
                                                            class="mb-1"></h6>
                                                        </div>

                                                        <div>
                                                            <div class="mb-2">
                                                                <p class="text-mute">Stato</p>
                                                                <p class="text-uppercase text-500">{{ $filters.toHumanOrderStatus(item.status) }}</p>
                                                            </div>

                                                            <!-- <div class="mb-2">
                                                                <p class="text-mute">Data richiesta</p>
                                                                <p>{{ $filters.toHumanDate(item.created_at) }}</p>
                                                            </div> -->

                                                            <div class="mb-2">
                                                                <p class="text-mute">Periodo di prestito richiesto</p>

                                                                <p>
                                                                    <span>{{ $filters.toHumanDate(item.loan.from_date) }}</span>
                                                                    <bxs-icon name="arrow-right" class="mx-2"></bxs-icon>
                                                                    <span>{{ $filters.toHumanDate(item.loan.to_date) }}</span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div
                                                    v-if="item.status === 'approved' || item.status === 'pending'"
                                                    class="text-right">
                                                        <bxs-menu :ref="'orderMenu' + item.id">
                                                            <template #header>
                                                                <bxs-title>{{ item.product.name }}</bxs-title>
                                                                <p>
                                                                    <span>{{ $filters.toHumanDate(item.loan.from_date) }}</span>
                                                                    <bxs-icon name="arrow-right" class="mx-2"></bxs-icon>
                                                                    <span>{{ $filters.toHumanDate(item.loan.to_date) }}</span>
                                                                </p>
                                                            </template>

                                                            <bxs-list>
                                                                <bxs-list-item
                                                                v-if="item.status === 'approved'"
                                                                spacer
                                                                link
                                                                @click="extendOrder(item)">
                                                                    <span>Estendi prestito</span>
                                                                    <bxs-icon name="arrow-right" class="mx-2"></bxs-icon>
                                                                    <span>{{ $filters.toHumanDateNextMonth(item.loan.to_date) }}</span>
                                                                </bxs-list-item>

                                                                <bxs-list-item
                                                                v-if="item.status === 'pending'"
                                                                :to="`/me/orders/${item.id}`"
                                                                spacer
                                                                append-icon="close">Annulla prestito</bxs-list-item>
                                                            </bxs-list>
                                                        </bxs-menu>

                                                        <!-- <p>
                                                            <span>{{ $filters.toHumanDateNextMonth(item.loan.from_date) }}</span>
                                                            <bxs-icon name="arrow-right" class="mx-2"></bxs-icon>
                                                            <span>{{ $filters.toHumanDateNextMonth(item.loan.to_date) }}</span>
                                                        </p> -->

                                                        <!-- <bxs-btn
                                                        v-if="item.status === 'pending'"
                                                        :to="`/me/orders/${item.id}`"
                                                        color="primary"
                                                        text
                                                        append-icon="chevron-right">Annulla prestito</bxs-btn> -->
                                                    </div>
                                                </div>

                                                <div
                                                v-if="item.note"
                                                class="mt-ui">
                                                    <div class="mb-1">
                                                        <p class="text-mute">Note</p>
                                                    </div>

                                                    <div v-html="$filters.mdToHtml(item.note)"></div>
                                                </div>

                                                <div
                                                v-if="item.note_answers"
                                                class="mt-ui">
                                                    <div class="mb-1">
                                                        <h6>Risposte alle tue domande</h6>
                                                    </div>

                                                    <div v-html="item.note_answers"></div>
                                                </div>
                                            </bxs-col>
                                        </bxs-row>
                                    </bxs-card>
                                </li>
                            </ul>
                        </div>

                        <!-- ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
                        ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ -->
                        <div
                        v-if="computed_orders.approved.length > 0"
                        class="mb-ui">
                            <div class="mb-ui">
                                <h5>Approvati</h5>
                            </div>

                            <ul>
                                <li
                                v-for="item in computed_orders.approved"
                                :key="item.id"
                                class="mb-ui">
                                    <bxs-card class="pa-ui">
                                        <bxs-row>
                                            <bxs-col cols="2">
                                                <bxs-figure
                                                ratio="1"
                                                :src="$filters.toStaticFile(item.product.img.url)"
                                                max-width="320"
                                                class="bxs-pointer mb-ui mx-auto"
                                                @click="$router.push('/products/' + item.product.id)"></bxs-figure>
                                            </bxs-col>

                                            <bxs-col
                                            cols="10"
                                            class="pl-ui">
                                                <!-- <div
                                                v-if="item.library"
                                                class="mb-ui">
                                                    <img
                                                    :src="$filters.toStaticFile(item.library.logo)"
                                                    :alt="`${$store.state.library ? $store.state.library.name : ''} logo`"
                                                    style="max-width: 48px;">
                                                </div> -->

                                                <div class="flex nowrap">
                                                    <div class="flex-1">
                                                        <div class="bxs-truncated mb-ui">
                                                            <h6
                                                            v-html="item.product.name"
                                                            class="mb-1"></h6>
                                                        </div>

                                                        <div>
                                                            <div class="mb-2">
                                                                <p class="text-mute">Stato</p>
                                                                <p class="text-uppercase text-500">{{ $filters.toHumanOrderStatus(item.status) }}</p>
                                                            </div>

                                                            <!-- <div class="mb-2">
                                                                <p class="text-mute">Data richiesta</p>
                                                                <p>{{ $filters.toHumanDate(item.created_at) }}</p>
                                                            </div> -->

                                                            <div class="mb-2">
                                                                <p class="text-mute">Periodo di prestito richiesto</p>

                                                                <p>
                                                                    <span>{{ $filters.toHumanDate(item.loan.from_date) }}</span>
                                                                    <bxs-icon name="arrow-right" class="mx-2"></bxs-icon>
                                                                    <span>{{ $filters.toHumanDate(item.loan.to_date) }}</span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div
                                                    v-if="item.status === 'approved' || item.status === 'pending'"
                                                    class="text-right">
                                                        <bxs-menu :ref="'orderMenu' + item.id">
                                                            <template #header>
                                                                <bxs-title>{{ item.product.name }}</bxs-title>
                                                                <p>
                                                                    <span>{{ $filters.toHumanDate(item.loan.from_date) }}</span>
                                                                    <bxs-icon name="arrow-right" class="mx-2"></bxs-icon>
                                                                    <span>{{ $filters.toHumanDate(item.loan.to_date) }}</span>
                                                                </p>
                                                            </template>

                                                            <bxs-list>
                                                                <bxs-list-item
                                                                v-if="item.status === 'approved'"
                                                                spacer
                                                                link
                                                                @click="extendOrder(item)">
                                                                    <span>Estendi prestito</span>
                                                                    <bxs-icon name="arrow-right" class="mx-2"></bxs-icon>
                                                                    <span>{{ $filters.toHumanDateNextMonth(item.loan.to_date) }}</span>
                                                                </bxs-list-item>

                                                                <bxs-list-item
                                                                v-if="item.status === 'pending'"
                                                                :to="`/me/orders/${item.id}`"
                                                                spacer
                                                                append-icon="close">Annulla prestito</bxs-list-item>

                                                                <bxs-list-item
                                                                :to="`/me/orders/${item.id}/review`"
                                                                spacer
                                                                append-icon="star">Lascia una recensione</bxs-list-item>
                                                            </bxs-list>
                                                        </bxs-menu>

                                                        <!-- <p>
                                                            <span>{{ $filters.toHumanDateNextMonth(item.loan.from_date) }}</span>
                                                            <bxs-icon name="arrow-right" class="mx-2"></bxs-icon>
                                                            <span>{{ $filters.toHumanDateNextMonth(item.loan.to_date) }}</span>
                                                        </p> -->

                                                        <!-- <bxs-btn
                                                        v-if="item.status === 'pending'"
                                                        :to="`/me/orders/${item.id}`"
                                                        color="primary"
                                                        text
                                                        append-icon="chevron-right">Annulla prestito</bxs-btn> -->
                                                    </div>
                                                </div>

                                                <div
                                                v-if="item.note"
                                                class="mt-ui">
                                                    <div class="mb-1">
                                                        <p class="text-mute">Note</p>
                                                    </div>

                                                    <div v-html="$filters.mdToHtml(item.note)"></div>
                                                </div>

                                                <div
                                                v-if="item.note_answers"
                                                class="mt-ui">
                                                    <div class="mb-1">
                                                        <h6>Risposte alle tue domande</h6>
                                                    </div>

                                                    <div v-html="item.note_answers"></div>
                                                </div>
                                            </bxs-col>
                                        </bxs-row>
                                    </bxs-card>
                                </li>
                            </ul>
                        </div>

                        <!-- ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
                        ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ -->
                        <div
                        v-if="computed_orders.extended.length > 0"
                        class="mb-ui">
                            <div class="mb-ui">
                                <h5>Estesi</h5>
                            </div>

                            <ul>
                                <li
                                v-for="item in computed_orders.extended"
                                :key="item.id"
                                class="mb-ui">
                                    <bxs-card class="pa-ui">
                                        <bxs-row>
                                            <bxs-col cols="2">
                                                <bxs-figure
                                                ratio="1"
                                                :src="$filters.toStaticFile(item.product.img.url)"
                                                max-width="320"
                                                class="bxs-pointer mb-ui mx-auto"
                                                @click="$router.push('/products/' + item.product.id)"></bxs-figure>
                                            </bxs-col>

                                            <bxs-col
                                            cols="10"
                                            class="pl-ui">
                                                <!-- <div
                                                v-if="item.library"
                                                class="mb-ui">
                                                    <img
                                                    :src="$filters.toStaticFile(item.library.logo)"
                                                    :alt="`${$store.state.library ? $store.state.library.name : ''} logo`"
                                                    style="max-width: 48px;">
                                                </div> -->

                                                <div class="flex nowrap">
                                                    <div class="flex-1">
                                                        <div class="bxs-truncated mb-ui">
                                                            <h6
                                                            v-html="item.product.name"
                                                            class="mb-1"></h6>
                                                        </div>

                                                        <div>
                                                            <div class="mb-2">
                                                                <p class="text-mute">Stato</p>
                                                                <p class="text-uppercase text-500">{{ $filters.toHumanOrderStatus(item.status) }}</p>
                                                            </div>

                                                            <!-- <div class="mb-2">
                                                                <p class="text-mute">Data richiesta</p>
                                                                <p>{{ $filters.toHumanDate(item.created_at) }}</p>
                                                            </div> -->

                                                            <div class="mb-2">
                                                                <p class="text-mute">Periodo di prestito richiesto</p>

                                                                <p>
                                                                    <span>{{ $filters.toHumanDate(item.loan.from_date) }}</span>
                                                                    <bxs-icon name="arrow-right" class="mx-2"></bxs-icon>
                                                                    <span>{{ $filters.toHumanDate(item.loan.to_date) }}</span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div
                                                    v-if="item.status === 'approved' || item.status === 'pending'"
                                                    class="text-right">
                                                        <bxs-menu :ref="'orderMenu' + item.id">
                                                            <template #header>
                                                                <bxs-title>{{ item.product.name }}</bxs-title>
                                                                <p>
                                                                    <span>{{ $filters.toHumanDate(item.loan.from_date) }}</span>
                                                                    <bxs-icon name="arrow-right" class="mx-2"></bxs-icon>
                                                                    <span>{{ $filters.toHumanDate(item.loan.to_date) }}</span>
                                                                </p>
                                                            </template>

                                                            <bxs-list>
                                                                <bxs-list-item
                                                                v-if="item.status === 'approved'"
                                                                spacer
                                                                link
                                                                @click="extendOrder(item)">
                                                                    <span>Estendi prestito</span>
                                                                    <bxs-icon name="arrow-right" class="mx-2"></bxs-icon>
                                                                    <span>{{ $filters.toHumanDateNextMonth(item.loan.to_date) }}</span>
                                                                </bxs-list-item>

                                                                <bxs-list-item
                                                                v-if="item.status === 'pending'"
                                                                :to="`/me/orders/${item.id}`"
                                                                spacer
                                                                append-icon="close">Annulla prestito</bxs-list-item>

                                                                <bxs-list-item
                                                                :to="`/me/orders/${item.id}/review`"
                                                                spacer
                                                                append-icon="star">Lascia una recensione</bxs-list-item>
                                                            </bxs-list>
                                                        </bxs-menu>

                                                        <!-- <p>
                                                            <span>{{ $filters.toHumanDateNextMonth(item.loan.from_date) }}</span>
                                                            <bxs-icon name="arrow-right" class="mx-2"></bxs-icon>
                                                            <span>{{ $filters.toHumanDateNextMonth(item.loan.to_date) }}</span>
                                                        </p> -->

                                                        <!-- <bxs-btn
                                                        v-if="item.status === 'pending'"
                                                        :to="`/me/orders/${item.id}`"
                                                        color="primary"
                                                        text
                                                        append-icon="chevron-right">Annulla prestito</bxs-btn> -->
                                                    </div>
                                                </div>

                                                <div
                                                v-if="item.note"
                                                class="mt-ui">
                                                    <div class="mb-1">
                                                        <p class="text-mute">Note</p>
                                                    </div>

                                                    <div v-html="$filters.mdToHtml(item.note)"></div>
                                                </div>

                                                <div
                                                v-if="item.note_answers"
                                                class="mt-ui">
                                                    <div class="mb-1">
                                                        <h6>Risposte alle tue domande</h6>
                                                    </div>

                                                    <div v-html="item.note_answers"></div>
                                                </div>
                                            </bxs-col>
                                        </bxs-row>
                                    </bxs-card>
                                </li>
                            </ul>
                        </div>

                        <!-- ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
                        ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ -->
                        <div
                        v-if="computed_orders.returned.length > 0"
                        class="mb-ui">
                            <div class="mb-ui">
                                <h5>Restituiti</h5>
                            </div>

                            <ul>
                                <li
                                v-for="item in computed_orders.returned"
                                :key="item.id"
                                class="mb-ui">
                                    <bxs-card class="pa-ui">
                                        <bxs-row>
                                            <bxs-col cols="2">
                                                <bxs-figure
                                                ratio="1"
                                                :src="$filters.toStaticFile(item.product.img.url)"
                                                max-width="320"
                                                class="bxs-pointer mb-ui mx-auto"
                                                @click="$router.push('/products/' + item.product.id)"></bxs-figure>
                                            </bxs-col>

                                            <bxs-col
                                            cols="10"
                                            class="pl-ui">
                                                <!-- <div
                                                v-if="item.library"
                                                class="mb-ui">
                                                    <img
                                                    :src="$filters.toStaticFile(item.library.logo)"
                                                    :alt="`${$store.state.library ? $store.state.library.name : ''} logo`"
                                                    style="max-width: 48px;">
                                                </div> -->

                                                <div class="flex nowrap">
                                                    <div class="flex-1">
                                                        <div class="bxs-truncated mb-ui">
                                                            <h6
                                                            v-html="item.product.name"
                                                            class="mb-1"></h6>
                                                        </div>

                                                        <div>
                                                            <div class="mb-2">
                                                                <p class="text-mute">Stato</p>
                                                                <p class="text-uppercase text-500">{{ $filters.toHumanOrderStatus(item.status) }}</p>
                                                            </div>

                                                            <!-- <div class="mb-2">
                                                                <p class="text-mute">Data richiesta</p>
                                                                <p>{{ $filters.toHumanDate(item.created_at) }}</p>
                                                            </div> -->

                                                            <div class="mb-2">
                                                                <p class="text-mute">Periodo di prestito richiesto</p>

                                                                <p>
                                                                    <span>{{ $filters.toHumanDate(item.loan.from_date) }}</span>
                                                                    <bxs-icon name="arrow-right" class="mx-2"></bxs-icon>
                                                                    <span>{{ $filters.toHumanDate(item.loan.to_date) }}</span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="text-right">
                                                        <bxs-menu :ref="'orderMenu' + item.id">
                                                            <template #header>
                                                                <bxs-title>{{ item.product.name }}</bxs-title>
                                                                <p>
                                                                    <span>{{ $filters.toHumanDate(item.loan.from_date) }}</span>
                                                                    <bxs-icon name="arrow-right" class="mx-2"></bxs-icon>
                                                                    <span>{{ $filters.toHumanDate(item.loan.to_date) }}</span>
                                                                </p>
                                                            </template>

                                                            <bxs-list>
                                                                <bxs-list-item
                                                                :to="`/me/orders/${item.id}/review`"
                                                                spacer
                                                                append-icon="star">Lascia una recensione</bxs-list-item>
                                                            </bxs-list>
                                                        </bxs-menu>

                                                        <!-- <p>
                                                            <span>{{ $filters.toHumanDateNextMonth(item.loan.from_date) }}</span>
                                                            <bxs-icon name="arrow-right" class="mx-2"></bxs-icon>
                                                            <span>{{ $filters.toHumanDateNextMonth(item.loan.to_date) }}</span>
                                                        </p> -->

                                                        <!-- <bxs-btn
                                                        v-if="item.status === 'pending'"
                                                        :to="`/me/orders/${item.id}`"
                                                        color="primary"
                                                        text
                                                        append-icon="chevron-right">Annulla prestito</bxs-btn> -->
                                                    </div>
                                                </div>

                                                <div
                                                v-if="item.note"
                                                class="mt-ui">
                                                    <div class="mb-1">
                                                        <p class="text-mute">Note</p>
                                                    </div>

                                                    <div v-html="$filters.mdToHtml(item.note)"></div>
                                                </div>

                                                <div
                                                v-if="item.note_answers"
                                                class="mt-ui">
                                                    <div class="mb-1">
                                                        <h6>Risposte alle tue domande</h6>
                                                    </div>

                                                    <div v-html="item.note_answers"></div>
                                                </div>
                                            </bxs-col>
                                        </bxs-row>
                                    </bxs-card>
                                </li>
                            </ul>
                        </div>

                        <!-- ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
                        ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ -->
                        <div
                        v-if="computed_orders.cancelled.length > 0"
                        class="mb-ui">
                            <div class="mb-ui">
                                <h5>Annullati</h5>
                            </div>

                            <ul>
                                <li
                                v-for="item in computed_orders.cancelled"
                                :key="item.id"
                                class="mb-ui">
                                    <bxs-card class="pa-ui">
                                        <bxs-row>
                                            <bxs-col cols="2">
                                                <bxs-figure
                                                ratio="1"
                                                :src="$filters.toStaticFile(item.product.img.url)"
                                                max-width="320"
                                                class="bxs-pointer mb-ui mx-auto"
                                                @click="$router.push('/products/' + item.product.id)"></bxs-figure>
                                            </bxs-col>

                                            <bxs-col
                                            cols="10"
                                            class="pl-ui">
                                                <!-- <div
                                                v-if="item.library"
                                                class="mb-ui">
                                                    <img
                                                    :src="$filters.toStaticFile(item.library.logo)"
                                                    :alt="`${$store.state.library ? $store.state.library.name : ''} logo`"
                                                    style="max-width: 48px;">
                                                </div> -->

                                                <div class="flex nowrap">
                                                    <div class="flex-1">
                                                        <div class="bxs-truncated mb-ui">
                                                            <h6
                                                            v-html="item.product.name"
                                                            class="mb-1"></h6>
                                                        </div>

                                                        <div>
                                                            <div class="mb-2">
                                                                <p class="text-mute">Stato</p>
                                                                <p class="text-uppercase text-500">{{ $filters.toHumanOrderStatus(item.status) }}</p>
                                                            </div>

                                                            <!-- <div class="mb-2">
                                                                <p class="text-mute">Data richiesta</p>
                                                                <p>{{ $filters.toHumanDate(item.created_at) }}</p>
                                                            </div> -->

                                                            <div class="mb-2">
                                                                <p class="text-mute">Periodo di prestito richiesto</p>

                                                                <p>
                                                                    <span>{{ $filters.toHumanDate(item.loan.from_date) }}</span>
                                                                    <bxs-icon name="arrow-right" class="mx-2"></bxs-icon>
                                                                    <span>{{ $filters.toHumanDate(item.loan.to_date) }}</span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div
                                                    v-if="item.status === 'approved' || item.status === 'pending'"
                                                    class="text-right">
                                                        <bxs-menu :ref="'orderMenu' + item.id">
                                                            <template #header>
                                                                <bxs-title>{{ item.product.name }}</bxs-title>
                                                                <p>
                                                                    <span>{{ $filters.toHumanDate(item.loan.from_date) }}</span>
                                                                    <bxs-icon name="arrow-right" class="mx-2"></bxs-icon>
                                                                    <span>{{ $filters.toHumanDate(item.loan.to_date) }}</span>
                                                                </p>
                                                            </template>

                                                            <bxs-list>
                                                                <bxs-list-item
                                                                v-if="item.status === 'approved'"
                                                                spacer
                                                                link
                                                                @click="extendOrder(item)">
                                                                    <span>Estendi prestito</span>
                                                                    <bxs-icon name="arrow-right" class="mx-2"></bxs-icon>
                                                                    <span>{{ $filters.toHumanDateNextMonth(item.loan.to_date) }}</span>
                                                                </bxs-list-item>

                                                                <bxs-list-item
                                                                v-if="item.status === 'pending'"
                                                                :to="`/me/orders/${item.id}`"
                                                                spacer
                                                                append-icon="close">Annulla prestito</bxs-list-item>
                                                            </bxs-list>
                                                        </bxs-menu>

                                                        <!-- <p>
                                                            <span>{{ $filters.toHumanDateNextMonth(item.loan.from_date) }}</span>
                                                            <bxs-icon name="arrow-right" class="mx-2"></bxs-icon>
                                                            <span>{{ $filters.toHumanDateNextMonth(item.loan.to_date) }}</span>
                                                        </p> -->

                                                        <!-- <bxs-btn
                                                        v-if="item.status === 'pending'"
                                                        :to="`/me/orders/${item.id}`"
                                                        color="primary"
                                                        text
                                                        append-icon="chevron-right">Annulla prestito</bxs-btn> -->
                                                    </div>
                                                </div>

                                                <div
                                                v-if="item.note"
                                                class="mt-ui">
                                                    <div class="mb-1">
                                                        <p class="text-mute">Note</p>
                                                    </div>

                                                    <div v-html="$filters.mdToHtml(item.note)"></div>
                                                </div>

                                                <div
                                                v-if="item.note_answers"
                                                class="mt-ui">
                                                    <div class="mb-1">
                                                        <h6>Risposte alle tue domande</h6>
                                                    </div>

                                                    <div v-html="item.note_answers"></div>
                                                </div>
                                            </bxs-col>
                                        </bxs-row>
                                    </bxs-card>
                                </li>
                            </ul>
                        </div>

                        <!-- ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
                        ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ -->
                        <div
                        v-if="computed_orders.denied.length > 0"
                        class="mb-ui">
                            <div class="mb-ui">
                                <h5>Negati</h5>
                            </div>

                            <ul>
                                <li
                                v-for="item in computed_orders.denied"
                                :key="item.id"
                                class="mb-ui">
                                    <bxs-card class="pa-ui">
                                        <bxs-row>
                                            <bxs-col cols="2">
                                                <bxs-figure
                                                ratio="1"
                                                :src="$filters.toStaticFile(item.product.img.url)"
                                                max-width="320"
                                                class="bxs-pointer mb-ui mx-auto"
                                                @click="$router.push('/products/' + item.product.id)"></bxs-figure>
                                            </bxs-col>

                                            <bxs-col
                                            cols="10"
                                            class="pl-ui">
                                                <!-- <div
                                                v-if="item.library"
                                                class="mb-ui">
                                                    <img
                                                    :src="$filters.toStaticFile(item.library.logo)"
                                                    :alt="`${$store.state.library ? $store.state.library.name : ''} logo`"
                                                    style="max-width: 48px;">
                                                </div> -->

                                                <div class="flex nowrap">
                                                    <div class="flex-1">
                                                        <div class="bxs-truncated mb-ui">
                                                            <h6
                                                            v-html="item.product.name"
                                                            class="mb-1"></h6>
                                                        </div>

                                                        <div>
                                                            <div class="mb-2">
                                                                <p class="text-mute">Stato</p>
                                                                <p class="text-uppercase text-500">{{ $filters.toHumanOrderStatus(item.status) }}</p>
                                                            </div>

                                                            <!-- <div class="mb-2">
                                                                <p class="text-mute">Data richiesta</p>
                                                                <p>{{ $filters.toHumanDate(item.created_at) }}</p>
                                                            </div> -->

                                                            <div class="mb-2">
                                                                <p class="text-mute">Periodo di prestito richiesto</p>

                                                                <p>
                                                                    <span>{{ $filters.toHumanDate(item.loan.from_date) }}</span>
                                                                    <bxs-icon name="arrow-right" class="mx-2"></bxs-icon>
                                                                    <span>{{ $filters.toHumanDate(item.loan.to_date) }}</span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div
                                                    v-if="item.status === 'approved' || item.status === 'pending'"
                                                    class="text-right">
                                                        <bxs-menu :ref="'orderMenu' + item.id">
                                                            <template #header>
                                                                <bxs-title>{{ item.product.name }}</bxs-title>
                                                                <p>
                                                                    <span>{{ $filters.toHumanDate(item.loan.from_date) }}</span>
                                                                    <bxs-icon name="arrow-right" class="mx-2"></bxs-icon>
                                                                    <span>{{ $filters.toHumanDate(item.loan.to_date) }}</span>
                                                                </p>
                                                            </template>

                                                            <bxs-list>
                                                                <bxs-list-item
                                                                v-if="item.status === 'approved'"
                                                                spacer
                                                                link
                                                                @click="extendOrder(item)">
                                                                    <span>Estendi prestito</span>
                                                                    <bxs-icon name="arrow-right" class="mx-2"></bxs-icon>
                                                                    <span>{{ $filters.toHumanDateNextMonth(item.loan.to_date) }}</span>
                                                                </bxs-list-item>

                                                                <bxs-list-item
                                                                v-if="item.status === 'pending'"
                                                                :to="`/me/orders/${item.id}`"
                                                                spacer
                                                                append-icon="close">Annulla prestito</bxs-list-item>
                                                            </bxs-list>
                                                        </bxs-menu>

                                                        <!-- <p>
                                                            <span>{{ $filters.toHumanDateNextMonth(item.loan.from_date) }}</span>
                                                            <bxs-icon name="arrow-right" class="mx-2"></bxs-icon>
                                                            <span>{{ $filters.toHumanDateNextMonth(item.loan.to_date) }}</span>
                                                        </p> -->

                                                        <!-- <bxs-btn
                                                        v-if="item.status === 'pending'"
                                                        :to="`/me/orders/${item.id}`"
                                                        color="primary"
                                                        text
                                                        append-icon="chevron-right">Annulla prestito</bxs-btn> -->
                                                    </div>
                                                </div>

                                                <div
                                                v-if="item.note"
                                                class="mt-ui">
                                                    <div class="mb-1">
                                                        <p class="text-mute">Note</p>
                                                    </div>

                                                    <div v-html="$filters.mdToHtml(item.note)"></div>
                                                </div>

                                                <div
                                                v-if="item.note_answers"
                                                class="mt-ui">
                                                    <div class="mb-1">
                                                        <h6>Risposte alle tue domande</h6>
                                                    </div>

                                                    <div v-html="item.note_answers"></div>
                                                </div>
                                            </bxs-col>
                                        </bxs-row>
                                    </bxs-card>
                                </li>
                            </ul>
                        </div>
                    </div>
                </bxs-layout>
            </section>
        </bxs-data-lazy>

        <!-- categories -->
        <categories-2-section />

        <!-- checkoutbar -->
        <checkoutbar to="/checkout/loan" />
    </div>
</template>

<script>
export default {
    name: 'me_orders',
    data () {
        return {
            data_page: null,
            filter: null
        }
    },
    computed: {
        orders_count () {
            let v = 0
            if (!this.data_page) return v

            this.data_page.forEach((o) => {
                v += o.docs.length
            })

            return v
        },
        computed_orders () {
            if (!this.data_page) return null

            const filter_is_setted = this.filter !== null
            const pending = this.data_page.find(i => i.id === 'pending')
            const extended = this.data_page.find(i => i.id === 'extended')
            const approved = this.data_page.find(i => i.id === 'approved')
            const returned = this.data_page.find(i => i.id === 'returned')
            const denied = this.data_page.find(i => i.id === 'denied')
            const cancelled = this.data_page.find(i => i.id === 'cancelled')

            return {
                pending: pending && pending.docs && (!filter_is_setted || this.filter === 'pending') ? pending.docs : [],
                extended: extended && extended.docs && (!filter_is_setted || this.filter === 'extended') ? extended.docs : [],
                approved: approved && approved.docs && (!filter_is_setted || this.filter === 'approved') ? approved.docs : [],
                returned: returned && returned.docs && (!filter_is_setted || this.filter === 'returned') ? returned.docs : [],
                denied: denied && denied.docs && (!filter_is_setted || this.filter === 'denied') ? denied.docs : [],
                cancelled: cancelled && cancelled.docs && (!filter_is_setted || this.filter === 'cancelled') ? cancelled.docs : []
            }
        }
    },
    methods: {
        click (item) {
            if (item.status === 'checkout') this.$router.push('/checkout/loan')
            else this.$router.push('/products/' + item.product.id)
        },
        async extendOrder (item) {
            this.$store.commit('set_is_loading', true)

            try {
                await this.$leila.me.extendOrder(item.id)

                // const _item = this.data_page.find(i => i.id === item.id)
                // _item.status = 'extended'
                // _item.loan.to_date = doc.to_date
                // this.$refs['orderMenu' + item.id][0].close()

                this.$toast.success('Estensione del prestito avvenuta con successo')
                this.$route.go()
            } catch (err) {
                throw new Error(err.message || err.code || err)
            } finally {
                this.$store.commit('set_is_loading', false)
            }
        }
    }
}
</script>