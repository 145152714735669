<template>
    <div
    sticky-container
    class="catalog-view">
        <bxs-loader
        v-if="!preload"
        fullscreen></bxs-loader>

        <div v-else>
            <bxs-top-nav
            undo="/"
            :sticky="false">
                <span>{{ data_page.dep && !!data_page.dep.name ? data_page.dep.name : '...' }}</span>

                <template #append>
                    <small>{{ data_page.pagination.total_docs }} oggetti</small>
                </template>
            </bxs-top-nav>

            <!-- categories -->
            <!-- <bxs-data-lazy :call="() => $leila.get('v1/categories')">
                <template #data="{ data }"> -->
                    <section v-sticky>
                        <bxs-layout>
                            <bxs-text-field
                            v-if="!!data_page.dep && !!data_page.dep.name"
                            v-model="keywords"
                            hide-form
                            clearable
                            name="keywords"
                            label="Cerca gli oggetti"
                            class="mb-ui mx-auto"
                            style="max-width: 90%;">
                                <template #prepend>
                                    <bxs-icon
                                    name="search"
                                    width="1.25em"></bxs-icon>
                                </template>
                            </bxs-text-field>

                            <bxs-carousel
                            ref="carousel_products_categories"
                            :items="products_categories"
                            freemode
                            scrollbar>
                                <template #item="{ item }">
                                   <bxs-chip
                                    :color="data_page.dep && !!data_page.dep.slug && item.slug === data_page.dep.slug ? 'primary' : null"
                                    :light="data_page.dep && !!data_page.dep.slug && item.slug === data_page.dep.slug"
                                    :dark="data_page.dep && !!data_page.dep.slug && item.slug === data_page.dep.slug"
                                    :to="`/catalogs/${item.slug}`">{{ item.name }}</bxs-chip>
                                </template>
                            </bxs-carousel>
                        </bxs-layout>
                    </section>
                <!-- </template>
            </bxs-data-lazy> -->

            <!--  -->
            <section>
                <bxs-layout>
                    <ul v-if="data_page.docs.length > 0">
                        <li
                        v-for="product in data_page.docs"
                        :key="product.id"
                        class="bxs-pointer mb-ui">
                            <product-row :item="product" />
                        </li>
                        <li
                        v-if="data_page.pagination.has_next_page"
                        class="text-center mt-ui">
                            <bxs-loader></bxs-loader>
                        </li>
                    </ul>

                    <div
                    v-else
                    class="text-center">
                        <div v-if="!!keywords">
                            <h6>Impossibile trovare</h6>
                            <h6 class="mb-1">"{{ keywords }}"</h6>
                        </div>

                        <div v-else>
                            <h6>Nessun oggetto trovato per</h6>
                            <h6 class="mb-1">"{{ $route.params.slug }}"</h6>
                        </div>

                        <p class="text-mute">Prova a effettuare nuovamente la ricerca.</p>
                    </div>
                </bxs-layout>
            </section>

            <transition name="fade" mode="out-in">
                <bxs-loader
                v-if="loading"
                fullscreen></bxs-loader>
            </transition>
        </div>

        <!-- headquarters -->
        <headquarters-section ref="headquarters" />

        <!-- checkoutbar -->
        <checkoutbar to="/checkout/loan" />
    </div>
</template>

<script>
import { mapState } from 'vuex'

import ProductRow from '@/components/project/ProductRow'

export default {
    name: 'catalog',
    components: {
        'product-row': ProductRow
    },
    computed: {
        ...mapState({
            products_categories: state => state.products_categories
        })
    },
    data () {
        return {
            preload: false,
            loading: false,
            busy: false,
            keywords: null,
            data_page: {
                dep: null,
                docs: [],
                pagination: {
                    page: 1
                }
            }
        }
    },
    mounted () {
        this.$nextTick(this.start)
    },
    watch: {
        keywords () {
            this.getDocs(1)
        }
    },
    methods: {
        async start () {
            await this.getDocs()
            this.preload = true
            window.addEventListener('scroll', this.handleScroll)
        },
        stop () {
            window.removeEventListener('scroll', this.handleScroll)
        },
        handleScroll () {
            // console.log(
            //     window.innerHeight,
            //     window.scrollY,
            //     this.$refs.headquarters.$el.clientHeight,
            //     '>=',
            //     document.body.scrollHeight,
            //     (window.innerHeight / 2)
            // )
            // console.log(
            //     (window.innerHeight + window.scrollY) >= (document.body.scrollHeight - (window.innerHeight / 2))
            // )

            if (
                (window.innerHeight + window.scrollY + this.$refs.headquarters.$el.clientHeight) >= (document.body.scrollHeight - (window.innerHeight / 2))
            ) {
                if (this.data_page.pagination.has_next_page && !this.busy) {
                    this.getDocs(this.data_page.pagination.page += 1)
                }
            }
        },
        async getDocs (page = 1, slug) {
            this.busy = true
            const filters = {}

            // if (this.keywords) {
            //     filters.name = '=|' + this.keywords
            // }

            try {
                const {
                    dep,
                    docs,
                    pagination
                } = await this.$leila.catalogs.getProducts(
                    slug || this.$route.params.slug,
                    {
                        limit: 10,
                        page: page,
                        keywords: this.keywords,
                        filters: filters
                    }
                )

                this.data_page.dep = dep

                if (page === 1) this.data_page.docs = docs
                else this.data_page.docs.push(...docs)

                this.data_page.pagination = pagination
            } catch (err) {
            } finally {
                this.busy = false
                this.loading = false
            }
        }
    },
    beforeRouteUpdate (to, from, next) {
        this.loading = true
        this.getDocs(1, to.params.slug)
        next()
        console.log('next')
    },
    beforeUnmount () {
        this.stop()
    }
}
</script>