import Auth from './Auth'
import User from './User'
import Product from './Product'
import Dep from './Dep'
import Customer from './Customer'
import Order from './Order'
import Checkout from './Checkout'
import Me from './Me'
import Catalog from './Catalog'
import Library from './Library'
import Support from './Support'

export const resources = {
    auth: {
        v: 'v1',
        path: 'auths',
        class: Auth
    },
    me: {
        v: 'v1',
        path: 'me',
        class: Me
    },
    libraries: {
        v: 'v1',
        path: 'libraries',
        class: Library
    },
    catalogs: {
        v: 'v1',
        path: 'catalogs',
        class: Catalog
    },
    customers: {
        v: 'v1',
        path: 'customers',
        class: Customer
    },
    orders: {
        v: 'v1',
        path: 'orders',
        class: Order
    },
    users: {
        v: 'v1',
        path: 'users',
        class: User
    },
    products: {
        v: 'v1',
        path: 'products',
        class: Product
    },
    deps: {
        v: 'v1',
        path: 'deps',
        class: Dep
    },
    checkout: {
        v: 'v1',
        path: 'checkout',
        class: Checkout
    },
    supports: {
        v: 'v1',
        path: 'supports',
        class: Support
    }
}

export function genResources(config, tools) {
    for (const key in resources) {
        const resource = resources[key]
        const Class = resource.class
        resources[key] = new Class(config, tools, resource.v, resource.path)
    }

    return resources
}
