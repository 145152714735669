<template>
    <Teleport to="body">
        <div
        v-if="$leila.auth.me && !!$leila.checkout.exists"
        class="checkout-bar bxs-no-select">
            <bxs-toolbar
            light
            color="black"
            :max-width="!$store.state.is_mobile ? 968 : '97%'"
            class="px-ui mb-1 bxs-material">
                <div
                v-if="!!$leila.checkout.product"
                class="flex nowrap align-center flex-1 py-ui"
                style="width: 50%;">
                    <bxs-figure
                    ratio="1"
                    :src="$filters.toStaticFile($leila.checkout.product.img.url)"
                    max-width="45"
                    class="bxs-pointer"
                    @click="$router.push('/products/' + $leila.checkout.product.id).catch(() => {})" />

                    <div class="bxs-truncate px-ui">
                        <p v-html="$leila.checkout.product.name"></p>

                        <p
                        v-if="!!$leila.checkout.loan.from_date && !!$leila.checkout.loan.to_date"
                        class="text-500">
                            <small>{{ $filters.toHumanDate($leila.checkout.loan.from_date, 'l') }}</small>
                            <bxs-icon name="arrow-right" class="mx-1"></bxs-icon>
                            <small>{{ $filters.toHumanDate($leila.checkout.loan.to_date, 'l') }}</small>
                            <!-- <small class="text-white ml-2">(giorni {{ $leila.checkout.loan.no_of_days }})</small> -->
                        </p>
                    </div>
                </div>

                <bxs-chip
                v-if="!$leila.checkout.exists"
                color="black"
                @click="$router.push('/me').catch(() => {})">{{ $leila.me.available_loans }} disponibili</bxs-chip>

                <bxs-chip
                v-if="!$leila.checkout.exists"
                color="success"
                small
                class="ml-ui text-500 text-uppercase"
                @click="$router.push('/me').catch(() => {})">{{ $leila.me.name[0] }}</bxs-chip>

                <bxs-btn
                v-if="!!$leila.checkout.exists && !!$leila.checkout.product"
                :loading="loading"
                :disabled="disabled || loading"
                append-icon="chevron-right"
                uppercase
                text
                small
                @click="click">Continua</bxs-btn>

                <bxs-menu
                v-if="!$store.state.is_mobile"
                icon>
                    <bxs-list>
                        <bxs-list-item
                        append-icon="chevron-right"
                        spacer
                        @click="click">Continua il checkout</bxs-list-item>

                        <bxs-list-item
                        append-icon="close"
                        spacer
                        @click="clearCart">Abbandona il checkout</bxs-list-item>
                    </bxs-list>
                </bxs-menu>

                <slot name="actions" />
            </bxs-toolbar>
        </div>
    </Teleport>
</template>

<script>
import Hammer from 'hammerjs'

export default {
    name: 'checkout-bar',
    props: {
        to: {
            type: [String, Object],
            required: false,
            default: null
        },
        loading: {
            type: Boolean,
            required: false,
            default: false
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    emits: [
        'click:next'
    ],
    data () {
        return {
            local_loading: false
        }
    },
    mounted () {
        this.$nextTick(this.play)
    },
    methods: {
        play () {
            if (this.$store.state.is_mobile) {
                const el = document.querySelector('.checkout-bar')
                const manager = new Hammer(el)
                const panner = new Hammer.Pan({
                    direction: Hammer.DIRECTION_LEFT,
                    threshold: 0
                })

                manager.add(panner)

                manager.on('panstart', (e) => {
                    // el.classList.remove('inner--transition')
                })

                manager.on('panmove', (e) => {
                    console.log('panmove', e)

                    el.style.transform = `translateX(${e.deltaX}px)`
                    el.style.opacity = 0.3
                })

                manager.on('panend', (e) => {
                    // console.log('panend', e)

                    if (e.center.x <= 0) {
                        this.local_loading = true
                        this.$leila.checkout.clear().then(() => {
                            this.local_loading = false

                            if (this.$route.name.includes('checkout_')) {
                                this.$router.go(-1)
                            }
                        }).catch(() => {
                            this.local_loading = false
                        })
                    } else {
                        el.style.transform = 'translateX(0px)'
                        el.style.opacity = 1
                    }
                })
            }
        },
        click () {
            if (this.to) this.$router.push(this.to).catch(() => {})
            this.$emit('click:next')
        },
        async clearCart () {
            this.$store.commit('set_is_loading', true)

            await this.$leila.checkout.clear()

            if (this.$route.name.includes('checkout_')) {
                this.$router.go(-1)
            }

            this.$store.commit('set_is_loading', false)
        }
    }
}
</script>

<style lang="scss" scoped>
.checkout-bar {
    position: fixed;
    left: 0;
    bottom: var(--navbar-height);
    width: 100vw;
    z-index: var(--layer-navbar);
    transition: transform 0.05s ease, opacity 0.5s ease;
}
</style>