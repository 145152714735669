<template>
    <div class="home-view">
        <bxs-toolbar
        color="background"
        class="mb-ui">
            <div class="bxs-truncate">
                <p class="h3">Ciao <span v-if="!!$leila.auth.me">{{ $leila.auth.me.name.split(' ')[0] }}</span> &#9996;</p>
            </div>

            <bxs-spacer></bxs-spacer>

            <!-- v-if="$store.state.library.id !== '133006000000057005'" -->
            <img
            v-if="$store.state.library.id === '133006000000057005'"
            src="/img/logo.png"
            alt="Leila biblioteca degli oggetti logo"
            style="max-width: 100px;">

            <img
            v-else
            :src="$filters.toStaticFile($store.state.library.logo)"
            :alt="`Leila biblioteca degli oggetti ${$store.state.library ? $store.state.library.name : ''} logo`"
            style="max-width: 100px;">
        </bxs-toolbar>

        <!-- welcome -->
        <section class="mb-ui">
            <bxs-layout>
                <div class="flex align-end nowrap">
                    <h1 class="h3 text-uppercase text-700 flex-1">
                        <span>Abbiamo bisogno</span>
                        <br>
                        <span>di utilizzare,</span>
                        <br>
                        <span class="text-outlined text-overline">non di possedere.</span>
                    </h1>
                </div>
            </bxs-layout>
        </section>

        <!--  -->
        <to-products-section />

        <!-- highlighted products -->
        <highlighted-products-section />

        <!-- categories -->
        <!-- <categories-2-section /> -->

        <!-- help -->
        <help-section />

        <!--  -->
        <signin-section />

        <!-- welcome -->
        <!-- <section>
            <bxs-layout>
                <h1>Lorem ipsum dolor sit</h1>
                <h2>Lorem ipsum dolor sit</h2>
                <h3>Lorem ipsum dolor sit</h3>
                <h4>Lorem ipsum dolor sit</h4>
                <h5>Lorem ipsum dolor sit</h5>
                <h6>Lorem ipsum dolor sit</h6>
            </bxs-layout>
        </section> -->

        <!-- checkoutbar -->
        <checkoutbar to="/checkout/loan" />
    </div>
</template>

<script>
export default {
    name: 'home',
    data () {
        return {
            loading: false,
            headquarters: []
        }
    }
}
</script>
