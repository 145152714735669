<template>
    <bxs-navbar
    v-if="is_preloaded && show_navbar && $route.name !== 'error_maintenance'"
    :access="access" />

    <!-- view -->
    <main v-if="is_preloaded" class="app__main">
        <router-view
        v-if="is_preloaded"
        v-slot="{ Component }"
        class="app__page">
            <transition name="page" mode="out-in">
                <component
                :is="Component"
                :access="access" />
            </transition>
        </router-view>
    </main>

    <transition name="fade" mode="out-in">
        <bxs-loader
        v-if="is_loading"
        fullscreen
        logo />
    </transition>

    <bxs-menu
    v-show="$route.name !== 'signin'"
    v-model="auth.on"
    solo
    max-width="440">
        <template #header>
            <bxs-title>Autenticazione scaduta!</bxs-title>
            <p>Accedi e continua!</p>
        </template>

        <div class="pa-layout">
            <bxs-form
            ref="form"
            @submit="signin">
                <bxs-text-field
                v-model="auth.email"
                name="email"
                required
                label="Email"
                class="mb-grid-gutter" />

                <bxs-text-field
                v-model="auth.password"
                required
                label="Password"
                type="password"
                name="password"
                class="mb-grid-gutter">
                    <template #append="{ on, value }">
                        <bxs-btn
                        v-on="on"
                        text
                        small>{{ !value ? 'show' : 'hide' }}</bxs-btn>
                    </template>
                </bxs-text-field>

                <bxs-alert
                v-if="auth.error"
                type="error">{{ error }}</bxs-alert>
            </bxs-form>
        </div>

        <template #actions>
            <div class="pa-layout">
                <bxs-btn
                block
                color="primary"
                light
                :disabled="auth.loading"
                :loading="auth.loading"
                @click="$refs.form.submit()">Accedi</bxs-btn>
            </div>
        </template>
    </bxs-menu>
</template>

<script>
import { mapState } from 'vuex'
import { fixVh } from '@/assets/libs/utils'

import Navbar from '@/components/layout/Navbar.vue'
export default {
    name: 'App',
    components: {
        'bxs-navbar': Navbar
        // 'bxs-footer': Footer
    },
    data () {
        return {
            auth: {
                on: false,
                error: null,
                loading: false,
                email: null,
                password: null
            }
        }
    },
    computed: {
        ...mapState({
            access: state => state.access,
            library: state => state.library,
            is_loading: state => state.is_loading,
            is_preloaded: state => state.is_preloaded
        }),
        show_navbar () {
            const v = ['policies']
            return !v.includes(this.$route.name)
        }
    },
    head () {
        return {
            title: `${this.library ? this.library.name : ''} | La biblioteca degli oggetti | Prenotazioni`,
            meta: [
                {
                    name: 'description',
                    content: 'Con Leila utilizzi e condividi tutti gli oggetti che vuoi, come vuoi, quando vuoi senza spendere un centesimo!'
                },
                // OG
                { name: 'og:type', content: 'article' },
                { name: 'og:site_name', content: 'leila-bologna.it' },
                { name: 'og:title', content: `${this.library ? this.library.name : ''} | La biblioteca degli oggetti | Prenotazioni` },
                { name: 'og:description', content: 'Con Leila utilizzi e condividi tutti gli oggetti che vuoi, come vuoi, quando vuoi senza spendere un centesimo!' },
                { name: 'og:image', content: 'https://app-leila.bxsdigital.com/uploads/sede.jpg' },
                { name: 'og:url', content: window.location.href },
                // TWC
                { name: 'twitter:card', content: 'summary_large_image' },
                { name: 'twitter:title', content: `${this.library ? this.library.name : ''} | La biblioteca degli oggetti | Prenotazioni` },
                { name: 'twitter:description', content: 'Con Leila utilizzi e condividi tutti gli oggetti che vuoi, come vuoi, quando vuoi senza spendere un centesimo!' },
                { name: 'twitter:image', content: 'https://app-leila.bxsdigital.com/uploads/sede.jpg' },
                { name: 'twitter:image:src', content: 'https://app-leila.bxsdigital.com/uploads/sede.jpg' },
                // indexing
                { name: 'robots', content: 'noindex, noarchive, nofollow' }
            ],
            script: [
                {
                    type: 'application/ld+json',
                    content: ''
                }
            ]
        }
    },
    mounted () {
        this.$nextTick(this.play)
    },
    watch: {
        'auth.on' (newval) {
            console.log(this.$leila.auth)

            if (!newval && this.$leila.auth.is_expired) {
                this.$router.push('/signin')
            }
        }
    },
    methods: {
        async play () {
            console.log(this.$leila)

            this.$store.commit('set_is_loading', true)
            this.resize()
            window.addEventListener('resize', this.resize)

            try {
                const library = await this.$leila.libraries.doc(this.$store.state.library_domain)
                this.$store.commit('set_library', library)

                if (!this.library.is.available) {
                    return this.$router.replace('/errors/maintenance')
                }
            } catch (err) {
                console.log('library not found', err)
                this.$store.commit('set_is_preloaded', true)
                this.$store.commit('set_is_loading', false)

                if (this.$route.name !== 'ui_components') {
                    return this.$router.replace('/errors/maintenance')
                }
            }

            await Promise.all([
                this.$leila.auth.getAccess(),
                this.$store.dispatch('getProductsCategories'),
                this.$store.dispatch('getHighlightedProducts')
            ])

            if (this.$leila.auth.me) {
                await this.$leila.me.get()
                await this.$leila.checkout.get()
            }

            if (this.$route.name === 'error_maintenance') {
                this.$router.replace('/')
            } else {
                console.log('play()', localStorage.getItem('lel_last_page'))

                this.$router.push(localStorage.getItem('lel_last_page') || '/').catch(() => {})
            }

            this.$store.commit('set_is_preloaded', true)
            this.$store.commit('set_is_loading', false)

            // this.$leila.on('access-token-refreshing', (evt) => {
            //     console.log('App on access-token-refreshing')
            // })

            // this.$leila.on('access-token-refreshed', (evt, token) => {
            //     console.log('App on access-token-refreshed')
            // })

            this.$leila.on('access-token-expired', (evt, val) => {
                console.log('App on access-token-expired')
                this.auth.on = true
            })
        },
        resize () {
            fixVh()
            this.$store.commit('resize')
        },
        async signin () {
            this.auth.error = null
            this.auth.loading = true

            try {
                await this.$leila.auth.signInWithEmailAndPassword(this.auth.email, this.auth.password)
                this.$leila.me.get()
                this.$leila.checkout.get()
                this.auth.on = false
                this.$route.go()
            } catch (err) {
                // this.error = 'E-mail o password non validi'
                if (err.status_code === 409) throw new Error('E-mail o password non validi')
                throw new Error(err.message || err.code)
            } finally {
                this.auth.loading = false
            }
        }
    }
}
</script>

<style lang="scss">
@import '@/assets/styles/index.scss';
</style>